@font-face {
    font-family: 'Aller Bold';
    src: url("../fonts/Aller\ Bold.ttf");
}

@font-face {
    font-family: 'Aller Regular';
    src: url("../fonts/Aller\ Regular.ttf");
}

@font-face {
    font-family: 'Fennywise-Regular';
    src: url("../fonts/Fennywise-Regular.otf");
}

body {
    color: #eaeaea;
    font-family: 'Aller Regular';
}

h3 {
    font-family: 'Aller Bold';
}

.link,
.nav-link {
    color: #eaeaea;
    font-family: 'Fennywise-Regular';
    letter-spacing: 1px;
    text-decoration: none;
}

.nav-link table {
    font-family: 'Aller Regular';
    font-size: 12px;
    letter-spacing: 0px;
}

.link:hover,
.nav-link:hover {
    color: #a5a5a5 !important;
}

.header-title {
    color: #eaeaea;
    font-family: 'Fennywise-Regular';
    letter-spacing: 1.3px;
}

.card-body {
    display: flex;
    flex-direction: column;
}

.card-title,
.card-title a {
    color: #eaeaea;
}

#container-page {
    min-height: 100vh;
}

#partner-page {
    padding-top: 40px;
}

/* ==============================
# Header
============================== */
.socmed {
    width: 100%;
    height: 35px;
    line-height: 10px;
    background-color: #003399;
    position: fixed;
    z-index: 1030;
}

.socmed span {
    font-size: 12px;
}

.navbarMenu {
    width: 100%;
    top: 35px;
    position: fixed;
    z-index: 1030;
}

.navbarMenu .container {
    height: 110px;
    position: fixed;
    top: 35px;
    left: 0;
    right: 0;
}

.navbarMenu .navbar-brand {
    z-index: 1030;
}

#prawiraLogo {
    width: 120px;
    height: 120px;
    transition: all 0.5s;
}

#prawiraLogo.logo-scrolled {
    transform: scale(0.8);
    margin-top: -20px;
}

.navbarMenu #toggleMenu {
    font-size: 14px;
    margin-top: -50px;
    background-color: #2b3035dd;
    z-index: 1030;
}

.navbarMenu #basic-navbar-nav {
    z-index: 1030;
    position: absolute;
    right: 10px;
}

.navbarMenu #navMenu {
    margin-top: -10px;
    transition: all 0.5s;
}

.navbar-nav .nav-link.active {
    color: #a5a5a5;
    text-decoration: underline;
}

.dropdown-item:active {
    background-color: transparent !important;
}

.dropdown-item.active {
    color: #a5a5a5;
    background-color: transparent;
    text-decoration: underline;
}

/* ==============================
# Carousel
============================== */
.banner img {
    width: 100%;
}

/* ==============================
# Game Schedule
============================== */
#gameScheduleBg {
    width: 100%;
    height: 120px;
    position: absolute;
    z-index: 1;
    margin: -120px 0;
    opacity: 0.6;
}

#gameSchedule {
    width: 100%;
}

#gameSchedule .team {
    height: 60px;
}

#gameSchedule .competition {
    height: 30px;
    margin-top: -40px;
}

#linkGameSchedule {
    color: #eaeaea;
    margin: auto;
}

.btnLinkLive {
    position: relative;
}

.btnLink {
    font-family: 'Aller Bold';
    font-size: 10px;
    position: absolute;
    background-color: #003399;
    border-color: #003399;
    padding: 2px 5px 0 5px;
}

.iconLive {
    position: absolute;
    margin: -7px -2px;
    font-size: 14px;
    color: #ff0000ee;
}

.competitionImg {
    height: 50px;
    margin-top: -10px;
}

.btnLinkTicket {
    background-color: #003399;
    border-color: #003399;
}

/* ==============================
# News
============================== */
.news .card,
.instagram .card {
    overflow: hidden;
}

.news img {
    transition: all 0.5s;
}

.news img:hover {
    transform: scale(1.1);
}

.instagram video {
    height: 50vh;
    object-fit: cover;
    object-position: top;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: all 0.5s;
}

.instagram img {
    height: 50vh;
    object-fit: cover;
    object-position: top;
    transition: all 0.5s;
}

.instagram video:hover,
.instagram img:hover {
    transform: scale(1.1);
}

.news .link,
.instagram .link {
    color: #eaeaea;
    font-family: 'Fennywise-Regular';
    text-decoration: none;
    font-size: 20px;
}

.news .link:hover,
.instagram .link:hover {
    color: #a5a5a5;
}

.news .card-text {
    font-size: 12px;
}

#caption {
    font-size: 10px;
}

.tags {
    font-size: 12px;
}

/* ==============================
# Gallery
============================== */
.gallery .card {
    overflow: hidden;
}

.gallery img {
    transition: all 0.5s;
}

.gallery img:hover {
    transform: scale(1.1);
}

.gallery .link {
    color: #eaeaea;
    font-family: 'Fennywise-Regular';
    text-decoration: none;
    font-size: 20px;
}

.gallery .link:hover {
    color: #a5a5a5;
}

/* ==============================
# Player
============================== */
.player {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position-y: 50px;
    background-position-x: center;
}

.player img {
    height: 350px;
    object-fit: cover;
    object-position: top;
    transition: all 0.5s;
}

.player img:hover {
    transform: scale(1.15);
}

.player .divTitleText {
    position: relative;
    background-color: #212529;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 40% !important;
}

.playerDetail .bgDetail {
    background-color: #003399;
}

.playerDetail #detail {
    margin-left: 12px;
}

.playerDetail #dob {
    position: absolute;
    font-size: 13px;
    margin-top: -10px;
}

.playerDetail #number {
    font-size: 120px;
}

/* ==============================
# Team
============================== */
.team {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position-y: 50px;
    background-position-x: center;
}

.team img {
    height: 350px;
    object-fit: cover;
    object-position: top;
    transition: all 0.5s;
}

.team img:hover {
    transform: scale(1.15);
}

.team .divTitleText {
    position: relative;
    background-color: #212529;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 30% !important;
}

.team .card-text {
    font-size: 14px;
}

/* ==============================
# Pagination
============================== */
ul.pagination {
    justify-content: center;
}

/* ==============================
# Embed image & video
============================== */
.newsDesc .video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
}

.newsDesc .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.newsDesc img {
    width: 100%;
}

/* ==============================
# Footer
============================== */
.footer {
    width: 100%;
    height: 50px;
    background-color: #003399;
    font-size: 12px;
}

.footer .name {
    color: #eaeaea;
    font-weight: bold;
}

/* ==============================
# Modal Ticket
============================== */
.modal-ticket .modal-content {
    background: #212529;
}

.modal-ticket .modal-header,
.modal-ticket .btn {
    font-family: 'Aller Bold';
}

.modal-ticket .btnPassport {
    background-color: #ab4aef;
    border-color: #ab4aef;
}

.modal-ticket .btnPassport:hover {
    background: #8e3bca;
    border-color: #8e3bca;
}

.modal-ticket .modal-body p,
.modal-ticket .modal-body ul li {
    font-size: 12px;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    right: 15px;
    bottom: 15px;
    background: #eaeaea;
    transition: all 0.4s;
    color: #003399;
}

.back-to-top:hover {
    background: #a5a5a5;
    color: #003399;
}

.back-to-top:active {
    background: #a5a5a5 !important;
    color: #003399 !important;
    border-color: #a5a5a5 !important;
}

@media (min-width:1200px) {

    /* ==============================
    # Carousel
    ============================== */
    .banner img {
        margin-top: -100px;
    }
}

@media (max-width:1200px) {

    /* ==============================
    # Header
    ============================== */
    .navbarMenu #navMenu {
        margin-top: -25px;
    }

    #prawiraLogo.logo-scrolled {
        margin-top: -20px;
    }

    /* ==============================
    # Carousel
    ============================== */
    .banner img {
        margin-top: -125px;
    }
}

@media (max-width:1024px) {

    /* ==============================
    # Header
    ============================== */
    .navbarMenu #navMenu {
        margin-top: -45px;
    }

    #prawiraLogo.logo-scrolled {
        margin-top: -20px;
    }

    /* ==============================
    # Carousel
    ============================== */
    .banner img {
        margin-top: -75px;
    }
}

@media (max-width:992px) {
    #container-page {
        padding-top: 120px;
    }

    /* ==============================
    # Header
    ============================== */
    .navbarMenu #navMenu {
        margin-top: -45px;
        padding: 0px 10px 0px 50px;
        background-color: #212529dd;
        border-radius: 5px;
    }

    .navbarMenu #bgHeader {
        height: 55px;
        position: absolute;
        top: 0;
        right: 0;
        /* background-color: #00339945; */
    }

    #prawiraLogo {
        width: 90px;
        height: 90px;
        margin-top: -25px;
    }

    #prawiraLogo.logo-scrolled {
        margin-top: -40px;
    }

    .navbarMenu #basic-navbar-nav {
        top: 100px;
    }

    /* ==============================
    # Game Schedule
    ============================== */
    .tbl-game-schedule {
        max-height: 1000px;
        overflow: auto;
        display: inline-block;
    }

    /* ==============================
    # News Instagram Gallery
    ============================== */
    .news .link,
    .instagram .link,
    .gallery .link {
        letter-spacing: 0.5px;
    }
}

@media (min-width:992px) {
    #container-page {
        padding-top: 150px;
    }

    /* ==============================
    # Header
    ============================== */
    .navbarMenu #basic-navbar-nav {
        top: auto;
    }

    .navbarMenu #navMenu {
        background-color: transparent;
    }

    .navbarMenu #bgHeader {
        width: 100%;
        position: absolute;
        top: 0;
        /* background-color: #00339945; */
    }

    /* ==============================
    # News
    ============================== */
    #searchNews {
        margin-top: -50px;
    }
}

@media (max-width:768px) {

    /* ==============================
    # Carousel
    ============================== */
    .banner img {
        margin-top: -35px;
    }

    /* ==============================
    # Game Schedule
    ============================== */
    #gameScheduleBg {
        display: none;
    }

    #gameSchedule .row {
        margin-top: -15px;
    }

    #gameSchedule {
        width: 100%;
        height: 130px;
        margin-top: 20px;
    }
}

@media (min-width:769px) {

    /* ==============================
    # Game Schedule
    ============================== */
    #gameSchedule .row {
        margin-top: 30px;
    }

    #gameSchedule {
        position: absolute;
        z-index: 1;
        margin: -120px 0;
    }

    #gameSchedule #title {
        position: absolute;
        left: 0;
        right: 0;
        top: 5px;
    }
}

@media (max-width:720px) {

    /* ==============================
    # Carousel
    ============================== */
    .banner img {
        margin-top: 0;
    }
}

@media (max-width:575px) {

    /* ==============================
    # Header
    ============================== */
    #prawiraLogo {
        width: 80px;
        height: 80px;
        margin-top: -35px;
    }

    #prawiraLogo.logo-scrolled {
        margin-top: -50px;
    }

    /* ==============================
    # Carousel
    ============================== */
    .banner img {
        margin-top: 35px;
    }

    /* ==============================
    # Player
    ============================== */
    .playerDetail .web {
        display: none;
    }

    /* ==============================
    # Footer
    ============================== */
    .footer {
        font-size: 10px;
    }
}

@media (min-width:575px) {

    /* ==============================
    # Player
    ============================== */
    .playerDetail .mobile {
        display: none;
    }
}

@media (max-width:475px) {

    /* ==============================
    # Header
    ============================== */
    #prawiraLogo {
        width: 70px;
        height: 70px;
        margin-top: -45px;
    }

    #prawiraLogo.logo-scrolled {
        margin-top: -50px;
        transform: scale(0.9);
    }
}